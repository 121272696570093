import React from 'react';
import NewsletterSubscriptionModal from './components/NewsletterSubscriptionModal';
import useHandleNewsletterSubscriptionAnswer from './hooks/useHandleNewsletterSubscriptionAnswer';
import useHandleNewsletterModalVisibility from './hooks/useHandleNewsletterModalVisibility';
import NewsletterSubscriptionForm from './components/NewsletterSubscriptionForm';
import Button, { ButtonStyles } from '../../../shared/buttons/Button';

type Props = {
    type?: 'modal' | 'footer';
};

const SubscribeToNewsletter: React.FC<Props> = ({ type = 'footer' }) => {
    const { visible, hideModal } = useHandleNewsletterModalVisibility(!!type);

    const { onValidSubmit, onDecline } = useHandleNewsletterSubscriptionAnswer(hideModal, type);

    if (!visible && type !== 'footer') {
        return null;
    }

    const renderForm = () => (
        <NewsletterSubscriptionForm
            onValidSubmit={onValidSubmit}
            trackingData={`newsletter-subscribe-${type}`}
            highlightButton={false}
        />
    );

    if (type === 'modal') {
        return (
            <NewsletterSubscriptionModal
                onDecline={onDecline}
                title={'Nieuw: zorgstandaarden Leefstijl en Somatische screening'}
                // title={'Wil jij op de hoogte blijven van de zorgstandaarden?'}
            >
                <p>
                    {/* Geef je, net als duizenden andere ggz-professionals, op voor de 2-wekelijkse nieuwsbrief van Akwa GGZ.*/}
                    Met kernaanbevelingen, samenvattingen en werkkaarten voor gebruik in de praktijk.
                </p>
                {/* {renderForm()}*/}
                {
                    <a
                        href={
                            'https://www.ggzstandaarden.nl/artikelen/217/nieuwe-zorgstandaarden-leefstijl-en-somatische-screening'
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Button style={ButtonStyles.primary}>Lees meer</Button>
                    </a>
                }
            </NewsletterSubscriptionModal>
        );
    }

    return renderForm();
};

export default SubscribeToNewsletter;
